import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ConfigurationBreadcrumbs/ConfigurationBreadcrumbs.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ConfigurationHeading.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/DevTools.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/apps/web/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/HelpMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/LoginButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/NotFoundPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/apps/web/src/components/Providers/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/RegisterForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ReportTabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/WorkflowLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/WorkflowMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/YearMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.66.9_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/next@15.1.7_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-date-picker@11.0.0_@types+react-dom@19.0.4_@types+react@19.0.10__@types+react@19._3ef368f5d52acc078d30e77337dc1c98/node_modules/react-date-picker/dist/DatePicker.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-calendar@5.1.0_@types+react@19.0.10_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-calendar/dist/Calendar.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-toastify@11.0.5_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/main.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/PwCHelveticaNeue/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/PwCHelveticaNeue/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/PwCHelveticaNeue/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"preload\":true}],\"variableName\":\"pwcHelveticaNeue\"}");
